import React, { useLayoutEffect, useState } from "react"
// OLD Header and footer
//import Header from "./Header"
//import Footer from "./Footer"
import { FaArrowUp } from "react-icons/fa"
import { useStaticQuery, graphql } from "gatsby"
import Header170424 from "./Header170424"
import Footer240524 from "./Footer240524"
const Layout = ({ children }) => {
  const handleGoTop = () => {
    window.scrollTo(0, 0)
  }
  const query = useStaticQuery(graphql`
    {
      allStrapiStyles {
        nodes {
          Global
        }
      }
    }
  `)

  const path = typeof window !== "undefined" ? window.location.pathname : ""
  const [aux, setAux] = useState(false)

  const data = query.allStrapiStyles.nodes[0].Global
  // const { data, loading, error } = useQuery(GLOBAL_STYLE)
  useLayoutEffect(() => {
    // DELETE?
    //const ELEMENT_HEADER = document.querySelector(".headerSelect")
    if (
      path === "/lp-mex/" ||
      path === "/lp-jp/" ||
      path === "/lp-jp" ||
      path === "/lp-mex" ||
      path === "/lp02/" ||
      path === "/lp02"
    ) {
      setAux(true)
    } else {
      // DELETE?
      //ELEMENT_HEADER.classList.replace("ns-menu", "s-menu")
      const ELEMENT_BUTTON = document.querySelector(".btn-goTop")
      window.addEventListener("scroll", e => {
        if (document.documentElement.scrollTop >= 50) {
          ELEMENT_BUTTON.classList.add("show-btn")
        } else {
          ELEMENT_BUTTON.classList.remove("show-btn")
        }
      })
    }
  }, [path])

  return (
    <>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />

      <style>.article-template__inner-html {data}</style>
      <style>.jobs-listing__top-listing--text-description {data}</style>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      ></link>
      <script
        src={`https://www.google.com/recaptcha/api.js?r=${Math.random()}`}
        async
        defer
      ></script>

      {aux ? "" : <Header170424></Header170424>}
      <script
        async
        src="https://cse.google.com/cse.js?cx=ac8b4ee2868a6b6bc"
      ></script>
      <div className="gcse-search"></div>
      {children}
      {aux ? (
        <script
          type="text/javascript"
          async=""
          src="https://www.googletagmanager.com/gtag/js?id=AW-823316446&amp;l=dataLayer&amp;cx=c"
        ></script>
      ) : (
        <>
          <Footer240524 />
          <button
            className="btn-goTop"
            onClick={handleGoTop}
            aria-label="Search-Top"
          >
            <FaArrowUp />
          </button>
        </>
      )}
    </>
  )
}

export default Layout
